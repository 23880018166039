import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './external/login/login.component';
import { ForgotPasswordComponent } from './external/forgot-password/forgot-password.component';
import { InternalComponent } from './internal/internal.component';
import { HeaderComponent } from './common/header/header.component';
import { SiderbarComponent } from './common/siderbar/siderbar.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { LoaderComponent } from './common/loader/loader.component';
import { InterceptorService } from './services/interceptor.service';
import { DataSharingService } from './services/data.share.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResetpasswordComponent } from './external/resetpassword/resetpassword.component';
import { LinkexpireComponent } from './external/linkexpire/linkexpire.component';
import { FaqComponent } from './external/faq/faq.component';


import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    InternalComponent,
    HeaderComponent,
    SiderbarComponent,
    PageNotFoundComponent,
    LoaderComponent,
    ResetpasswordComponent,
    FaqComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    CommonModule,
    AccordionModule.forRoot(),

  ],
  providers: [AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
    NgxSpinnerModule,
    DataSharingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
