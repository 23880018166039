import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './external/forgot-password/forgot-password.component';
import { LoginComponent } from './external/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { ResetpasswordComponent } from './external/resetpassword/resetpassword.component';
import { LinkexpireComponent } from './external/linkexpire/linkexpire.component';
import { FaqComponent } from './external/faq/faq.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'link-expired', component: LinkexpireComponent },
  { path: 'reset-password/:token', component: ResetpasswordComponent },
  // { path: 'reset-password', component: ResetpasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'faq', component: FaqComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)
  },

  { path: '**', component: PageNotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
