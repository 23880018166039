import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharingService {
    navigationData: any;
    constructor() { }

    public isLoginChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isProfileChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isPermissionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    

    public setNavtionData(data) {
    
        this.navigationData = null;
        this.navigationData = data;
        localStorage.setItem('navigationData',JSON.stringify(data));
    
      }
      public getNavtionData() {
        if(this.navigationData){
          return this.navigationData;
    
        }
        else{
          return JSON.parse(localStorage.getItem('navigationData')) || null;
        }
      } 

}

