import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { DataSharingService } from '../../services/data.share.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userName: any;
  constructor(private router: Router, private admin: AdminService,
    ) {
    // this.dataSharingService.isProfileChanged.subscribe(value => {

    // });
    this.userName = localStorage.getItem('name');
    // this.userName = '';
  }

  ngOnInit(): void {
  }
  logout() {

    swal.fire({
      icon: "warning",
      text: 'Are you sure you want to log out?',
      showCancelButton: true,
      confirmButtonColor: '#2FC863',
      cancelButtonColor: '#2C2C2C',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('role');
        localStorage.removeItem('access_token');
        localStorage.removeItem('userid');
        localStorage.removeItem('name');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('s3Url');
        localStorage.removeItem('s3Folders');

        this.router.navigate(['/login']);
      }
    });
  }
  inProgress(){
    swal.fire(
      'info',
      'Work in progress.',
      'info'
    )
    }


}
