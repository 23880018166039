import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment} from '../../environments/environment';
import swal from "sweetalert2";
import { Router } from '@angular/router';

import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { Subject } from 'rxjs';
import { apiUrl } from '../global/global';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  apiEndpoint: string = '';
  apiEndpoint_web: string = '';
  apiEndpoint_web_v2: string = '';
  showLoader: boolean = true;
  icon = icons;
  access_token: any;
  menu = menu;
  btnText = BtnText;
  generalModel = new generalModel();
  Permission = permission;
  public _albums = [];
  job_fun = [ 
    "Administration/Operations",
    "Services/Customer Support",
    "Marketing",
    "Sales",
    "Production",
    "Information Technology",
    "Legal",
    "Human Resources",
    "Finance/Accounting",
    "Engineering/R & D",
    "Purchasing",
    "Other"]
  constructor(private http: HttpClient, private router: Router) {
    this.apiEndpoint = environment.apiUrl;
    this.apiEndpoint_web = environment.apiUrl_web;
    this.apiEndpoint_web_v2 = environment.apiUrl_web_v2;
    this.access_token = localStorage.getItem('access_token');
    //console.log(this.access_token, " this.access_token")
    // if(!this.access_token){
    //   this.router.navigate(['/login']);
    // }

  }

  httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    })
    }

  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDataweb(url, param) {
    return this.http.get<any>(this.apiEndpoint_web + url, { params: param });
  }
  getDatas(url) {
    return this.http.get<any>(this.apiEndpoint + url);
  }

  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDetailById(url, param) {
    return this.http.get<any>(this.apiEndpoint_web + url, { params: param });
  }

  getDetail1(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetailList(url, param) {
    return this.http.get<any>(this.apiEndpoint_web + url, { params: param });
  }


  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, { params: param });
  }

   postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postDatas(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postDatasweb(url, value) {
    return this.http.post<any>(this.apiEndpoint_web + url, value);
  }
  postDataswebv2(url, value) {
    return this.http.post<any>(this.apiEndpoint_web_v2 + url, value);
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }
  postDataNew(url, key, value) {
    return this.http.post<any>(url, key, value);
  }
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  updateDataweb(url, value) {
    return this.http.patch<any>(this.apiEndpoint_web + url, value);
  }

  deleteData(url,  param) {
    return this.http.delete<any>(this.apiEndpoint +url,  {params: param});
  }
  deleteUser(id) {
    return this.http.post<any>(`${this.apiEndpoint_web }students/delete`, 
    {
      access_token: localStorage.getItem('access_token'),
      id: id
    },this.httpOptions);
  }

  

  getData1(url, param) {
    return this.http.get<any>(this.apiEndpoint_web + url, { params: param });
  }

  postData1(url, value) {
    return this.http.post<any>(this.apiEndpoint_web + url, value);
  }


  showAlert(title: string, text: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 1500
    })

  }

  showAlerts(title: string,text: string,confirmButtonText :string) {
    swal.fire({
      title: '<figure><img src="../../assets/images/sucess.svg"></figure>',
      text: 'Enrolled successfully.',
      showConfirmButton: true,
      confirmButtonText: 'Okay',
      confirmButtonColor: '#2FC863',
      timer: 1500
    })

  }
  errorAlert(text, status) {
    swal.fire({
      title: 'Oops...',
      text: text,
    }).then((result) => {
      if (status) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('type-redirect');
        localStorage.removeItem('image');
        localStorage.removeItem('permissionData');
        this.router.navigate(['/login']);
      }
    })
  }



  toast1(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 6000,
      background: '#5ECB5E',

    });
    toast.fire({
      title: title,
      text: text
    })
  }

  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 6000,
      background: '#5ECB5E',

    });
    toast.fire({
      title: title,
      text: text
    })
  }
  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) { this.loaderSubject.next(value); }

  setdata(data) {
    for (let x of tabArray) {
      let array1 = {}
      for (let y of x.value) {
        array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
      }
      permission[x.name] = array1;
    }
    permission['status'] = "updated";
    return true;
  }

  getStudentDetails(studentId: string) {
    const httpOptions = {
      params: new HttpParams()
      .set('access_token',localStorage.getItem('access_token'))
      .set('id',studentId),
      headers: new HttpHeaders({ 
        'Content-Type':'application/json',
      })
    }

    return this.http.get<any>(this.apiEndpoint_web + `students/${studentId}`, httpOptions);
  }

  updateStudent(studentId: string,data) {
    const httpOptions = {
      params: new HttpParams()
      .set('access_token',localStorage.getItem('access_token'))
      .set('user_id',studentId),
      headers: new HttpHeaders({ 
        'Content-Type':'application/json',
      })
    }

    return this.http.post<any>(`${this.apiEndpoint_web}user/profile`, data,httpOptions);
  }

  getDraftedMails() {
    return this.http.post<any>(`${this.apiEndpoint_web}invitations/pending`, 
    {
      access_token: localStorage.getItem('access_token'),
    }, this.httpOptions);
  }
  saveStudentInvitation(emails: string) {
    return this.http.post<any>(`${this.apiEndpoint_web}invitations/draft`,
    {
      access_token: localStorage.getItem('access_token'),
      emails: emails
    },this.httpOptions);
  }

  sendStudentInvitation(emails) {
    return this.http.post<any>(`${this.apiEndpoint_web}invitations/invite`, 
    {
      access_token: localStorage.getItem('access_token'),
      emails: emails.email,
      company_id: emails?.company_id,
    }, this.httpOptions);
  }

  deactivateuser(id: string) {
    return this.http.post<any>(`${this.apiEndpoint_web}students/is_active `, 
    {
      access_token: localStorage.getItem('access_token'),
      id: id,
      status: 1
    },this.httpOptions);
  }

  activateuser(id: string) {
    return this.http.post<any>(`${this.apiEndpoint_web}students/is_active `, 
    {
      access_token: localStorage.getItem('access_token'),
      id: id,
      status: 0
    },this.httpOptions);
  }
}
