import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { AdminService } from '../../services/admin.service';
import * as CONSTANT from '../../services/constants';
import { loginModel, resetPasswordModel } from '../../shared/models/login-model';
import { HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { apiUrl } from '../../global/global';
import * as CryptoJS from 'crypto-js';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public resetPasswordForm: FormGroup;
    showError = false;
    show = true;
    public loading = false;
    image: any;
    showPwd: boolean;
    loginModel = new loginModel();
    resetPasswordModel = new resetPasswordModel();
    rememberme = false;
    tokenFromUI = '0123456789123456';
    isRemember = false;
    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private api: AdminService
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required ])],
            password: ['', Validators.compose([Validators.required] ) ]
        });
        this.showPwd = false;
    }

    ngOnInit(): void {
        let data = localStorage.getItem('access_token');
        this.show = true;
        if (data)
            this.router.navigate(['/dashboard'],{ replaceUrl: true });
        let rememberme = localStorage.getItem('rememberme');
        console.log(rememberme, "rememberme");
        if (rememberme == '1') {
            this.loginModel.email = localStorage.getItem('email');
            this.decryptUsingAES256(localStorage.getItem('password'));
            this.isRemember = true;
            this.rememberme = true;
        }
    }
    password() {
        this.show = !this.show;
    }
    loginFun() {
        console.log("thr")
        if (this.form.valid) {
            this.api.apiEndpoint = environment.apiUrl;
            this.loading = true;
            const data = {
                'email': this.loginModel.email,
                'password': this.loginModel.password,
                //  'deviceToken': this.msg.getFcmToken()
            };
            this.api.postData(apiUrl._adminLogin, data)
                .subscribe(async (res) => {
                    console.log(res, "login============================");
                    this.loading = false;
                    localStorage.setItem('access_token', res.data.access_token);
                    console.log( res.data.access_token, "access_token");
                    console.log('Swa', res);
                    localStorage.setItem('name', res.data.first_name);
                    localStorage.setItem('role', res.data.role);
                    localStorage.setItem('loginEmail', res.data.email);
                    localStorage.setItem('userid', res.data.id);
                    //console.log( localStorage, "res=============");
                    let param = new HttpParams();

                    this.router.navigate(['/dashboard'],{ replaceUrl: true }
                    );
                    // this.api.toast('GD Admin', 'Login Successfull!');


                    if (this.rememberme) {
                        let encrptedPassword = await this.encryptUsingAES256(this.loginModel.password);
                        localStorage.setItem('rememberme', '1');
                        localStorage.setItem('email', this.loginModel.email);
                        localStorage.setItem('password', encrptedPassword.toString());
                    } else {
                        localStorage.setItem('rememberme', '0');
                        localStorage.setItem('email', '');
                        localStorage.setItem('password', '');
                    }
                },
                    err => {
                        console.log(err, "errr");
                        this.loading = false;
                    });
        } else {
            this.showError = true;
        }
    }

    forgotPassword() {
        this.show = false;
    }

    encryptUsingAES256(value) {
        return new Promise((resolve, reject) => {
            let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
            resolve(ciphertext);
        });

    }
    decryptUsingAES256(value) {
        let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        this.loginModel.password = plaintext;
    }

    backToLogin() {
        this.show = true;
    }



    setServer(event) {
        localStorage.setItem('server', event.target.value)
    }

    changeRememberme(event) {
        console.log("checked", event.target.checked);
        this.rememberme = event.target.checked;
    }

}
