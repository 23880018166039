export const apiUrl = {
    _adminLogin:'logins',
    _forgotPassword:'passwords/request_password',
    _resetPassword:'passwords/reset_password',

    _getStudentList:'students',
    _getStudentDetails:'students',
    _updateStudent:'students',

    _getCompanySearch:'companies/search',

    _getCompanyList:'companies',
    _getCompanyDetails:'companies',
    _addCompany:'companies',
    _updateCompany:'companies',
    _enrollList:'companies/enrollment_dropdown',

    _getCompanyInsights:'insights',
    
    _getTeamList:'teams',
    _searchTeamMember:'companies',
    _addNewteam:'teams',
    _updateTeam:'teams',
    _deleteTeam:'teams',
    _getMember:'teams',

    _delete_multiple:'teams/delete_multiple',
    _delete_multiple_company:'companies/delete_multiple',
    
    _getCategoriesList:'categories',
    _addCategories:'categories',
    _updateCategories:'categories',

    _getPointsList:'badges',
    _updatePoints:'badges',

    _getDashboard:'dashboard',

    _getNotifications:'notifications',
    _statusNotifications:'notifications',
    _updateNotification: 'notifications',

    _getbroadcasts:'broadcasts',
    _addbroadcasts:'broadcasts',
    
    _getAdminlist: 'admins',
    _getAdminDetails: 'admins',
    _updateAdmin: 'admins',
    _addAdmin: 'admins',

    _getSupportlist: 'supports',
    _getSupportDetails: 'supports',
    _updateSupport: 'supports',

    _getHelpContent: '/contents',
    _updateHelpContent: 'contents',

    _getProfileDetails: 'admins',
    
    _getreportList:'reporting',
    _getResult:'reporting/result',
    _getSave:'reporting/save',
    _getSavequery:'reporting/querrylist',
    _deleteReport:'reporting/delete',
    _getDeandata:'organizations/deanManagment',
    _sendData:'organizations/deaneAdd',
    _deleteDean:'organizations/deaneDeactive',
    _blockDean:'organizations/deanBlockUnblock',
    _editDean:'organizations/deanEdit',
};

