import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { AdminService } from '../../services/admin.service';
import * as CONSTANT from '../../services/constants';
import { loginModel, resetPasswordModel } from '../../shared/models/login-model';
import { HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { apiUrl } from '../../global/global';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public form: FormGroup;
  isForgotMailSend = false;
  public resetPasswordForm: FormGroup;
  showError = false;
  fwdEmail: any;
  show = true;
  access_token: any;
  public loading = false;
  resetPasswordModel = new resetPasswordModel();
  constructor(private formBuilder: FormBuilder,
    private router: Router,

    private api: AdminService
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],

    })
    this.access_token = localStorage.getItem('access_token');
  }

  ngOnInit(): void {
  }
  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.api.apiEndpoint = environment.apiUrl;
      this.loading = true;
      this.fwdEmail = this.resetPasswordModel.email;
      const data = {
        'email': this.resetPasswordModel.email
      };
      this.api.postData(apiUrl._forgotPassword, data)
        .subscribe(res => {
          this.loading = false;
          this.isForgotMailSend = true;
          this.api.toast1('', 'Please check your email to reset your password.');
          // swal.fire({
          //   icon: 'success',
          //   title: '<h2>Growth Drivers Admin</h2>',
          //   html: '<p>Your password email successfully sent on your registered email address</p>',
          //   showCloseButton: false,
          //   width: 500 
          // })
          //this.router.navigate(['/']);
        },
          err => {
            console.log(err);
            this.loading = false;
          });
    } else {
      this.showError = true;
    }
  }
  sendEmail() {
    this.api.apiEndpoint = environment.apiUrl;
    this.loading = true;
    const data = {
      'email': this.fwdEmail
    };
    this.api.postData(apiUrl._forgotPassword, data)
      .subscribe(res => {
        this.loading = false;
        this.isForgotMailSend = true;
        this.api.toast1('', 'Please check your email to reset your password.');
      },
        err => {
          console.log(err);
          this.loading = false;
        });

  }

}
