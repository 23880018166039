import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as CONSTANT from '../../services/constants';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  public form: FormGroup;
  public resetPasswordForm: FormGroup;
  showError = false;
  showError1 = false;
  show = true;
  public loading = false; 
  token: string;
  id1:any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private api: AdminService,
    private route: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')])],
      confirmPassword: ['', Validators.required],
    });  
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');

    this.id1 = this.router.url.indexOf("?token=");
    console.log(this.id1, "=============this.id1")
    console.log(this.token, "=============this.token")
    this.show = true;
    
  }
  onSubmit(form) {
    if (form.valid) {
      this.loading = true;
      if (form.value.password !== form.value.confirmPassword) {
        this.showError1 = true;
        setTimeout(() => {
          this.showError1 = false;
        }, 5000);
        return false;
      }
      const data = {
        'password': form.value.confirmPassword,
        'token': this.token
      };
      this.api.postData(apiUrl._resetPassword, data)
        .subscribe(res => {
          this.loading = false;
          this.router.navigate(['/login']);
          this.api.toast('Password Reset Successfull!', 'Growth Drivers Admin');
        },
          err => {
            console.log(err);
            this.loading = false;
          });
    } else {
      this.showError = true;
    }
  }

}
