import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";

@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.css']
})
export class SiderbarComponent implements OnInit {

  userRole : number = 2;

  constructor() { }

  ngOnInit(): void {
    this.userRole = parseInt(localStorage.getItem('role'));
  }

  inProgress(){
  swal.fire(
    'info',
    'Work in progress.',
    'info'
  )
  }

}
